@import "styles/gw-colors";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.tile_header {
  min-height: 70px;
}

.tile_action {
  display: block !important;
  color: theme-color("green");
  :hover {
    color: theme-color("white");
    background-color: theme-color("primary");
  }
}

figcaption.help {
  position: absolute;
  left: 100px;
  top: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: theme-color("white");
  transition: all 0.3s ease;
  background: rgba(theme-color("primary"), 0.9);
  width: 100%;
  height: 200px;
  padding: 10px;

  transform: translate(150%, -50%) rotate(0deg);
}

.cardbody__help {
  overflow: hidden;
  position: relative;
}
.cardbody__title {
  font-size: 1.25rem;
}
.cardbody__disabled {
  opacity: 0.5;
}

.cardbody__help:hover figcaption {
  transform: translate(-50%, -50%);
}
