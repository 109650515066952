$theme-colors: (
  "primary": #00467d,
  "blue": #00467d,
  "secondary": #dcdbd9,
  "green": #00c713,
  "indigo": #003763,
  "cyan": #37afb9,
  "purple": #500a78,
  "dark": #29343a,
  "gw-yellow": #d6e000,
  "gw-lightblue": #9bd7dc,
  "gw-light": #f9f9f9,
  "white": #fff,
  "pink":#d63384
);
