/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Branded for Gelsenwasser / wMSB
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import "styles/gw-colors";
@import "styles/gw-fonts";
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

// Die Einträge 0 bis 5 entsprechen den default bootstrap Werten (s. https://getbootstrap.com/docs/4.0/utilities/spacing/#notation)
// Die Einträge 6 bis 10 sind als Erweiterung dazu gedacht um auch größere Abstände ermöglichen zu können
$spacers: (
  0: 0,
  1: ($spacer * .25),    //4px
  2: ($spacer * .5),     //8px
  3: $spacer,            //16px
  4: ($spacer * 1.5),    //24px
  5: ($spacer * 3),      //48px
  6: ($spacer * 4),      //64px
  7: ($spacer * 5),      //80px
  8: ($spacer * 6.25),   //100px
  9: ($spacer * 7.5),    //120px
  10: ($spacer * 9.375)  //150px
);

$font-weight-bold: 500;
$font-weight-bolder: 700;

$enable-rounded: false;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1380px,
);

$jumbotron-padding: 2rem;
$jumbotron-bg: #fff;

$breadcrumb-padding-y: 0.75rem;
$breadcrumb-padding-x: 0rem;

$dropdown-item-padding-x: 0.5rem;

$font-family-sans-serif: "Roboto";

@import "styles/bs-functions";

$breadcrumb-bg: theme-color("gw-light");
$headings-color: theme-color("primary");

$table-accent-bg: #e4e4e4;
$custom-control-indicator-checked-bg: theme-color("primary");

// Farbe für Tooltips
$tooltip-bg: theme-color("secondary");
$tooltip-arrow-color: theme-color("secondary");
$tooltip-color: $black;

// keine Farbe für Header und Footer bei Cards
$card-cap-bg: null;
$card-cap-color: theme-color("primary");

// mehr Abstand vom Inhalt zum Rand
$modal-inner-padding: ($spacer * 1.5);
$modal-header-padding: ($spacer * 1.25) ($spacer * 1.5);

// mehr Abstand vom Inhalt zum Rand
$badge-padding-x: 0.75em;
$badge-padding-y: 0.5em;

@import "styles/bs-components";

// damit zwischen einzelnen zeilen ein abstand ist
.row {
  margin-top: $spacer;
}

// nur in modalen dialogen ...
.modal-body {
  padding: ($spacer * 1.25) ($spacer * 1.5);

  // sollte der abstand der zeilen geringer sein
  .row {
    margin-top: ($spacer * 0.5);
  }
}

// damit zwischen containern ein abstand ist
.container {
  margin-top: ($spacer * 0.5);
}

.card-title {
  font-family: "Roboto Condensed";
  font-size: $font-size-base * 1.5;
}
.card-header {
  font-size: $font-size-base * 1.5;
}

.tooltip-inner {
  text-align: left;
}

.display-4 {
  padding-bottom: ($spacer * 2);
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 10%;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid theme-color("primary");
    margin-bottom: $spacer;
  }
}

.bg-gradient {
  background: transparent linear-gradient(-225deg, theme-color("primary") 35%, theme-color("green") 100%) 0% 0%
    no-repeat padding-box;
}

.outermain {
  overflow-x: hidden;
}

.footer {
  height: 5em;
  position: static;
}

.spin {
  animation: spin 2s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.loginButton > button {
  border: 0;
}

// hintergrund von nicht ausgewählten custom-checkboxen
.custom-control-input ~ .custom-control-label::before {
  @include gradient-bg(theme-color("secondary"));
}

// generell für den Toggle: hintergrund ist weiß und Rand in der "aktiven" Farbe zudem ist der toggle bei nicht ausgewählten elementen in der aktiv-farbe
.custom-switch {
  .custom-control-label::before {
    background-color: $input-bg;
  }

  .custom-control-input:not(:disabled) ~ .custom-control-label::before {
    border-color: $custom-control-indicator-checked-bg;
  }
  .custom-control-input:not(:disabled):not(:checked) ~ .custom-control-label::after {
    background-color: $custom-control-indicator-checked-bg;
  }
}

.btn-listaction {
  @include button-variant($white, theme-color("primary"), theme-color("primary"), theme-color("primary"));
  color: theme-color("primary");
}

//TODO: FIXME!! Sollte eigentlich besser in dem Modul-CSS von der Tabelle sein?!
.row_highlighted {
  background-color: rgba(theme-color("info"), 0.28) !important;
}

.table-striped {
  tbody tr:nth-of-type(#{$table-striped-order}).row_highlighted {
    background-color: rgba(theme-color("info"), 0.4) !important;
  }
}
